import React from 'react';
import Config from '../../constants/appConfig';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles, Tooltip, MenuItem, Select, FormControl, Button, InputLabel } from '@material-ui/core';
import Moment from 'moment';
import thermalInversionExample from '../../assets/images/thermalInversionExample.png';
import normalFlowExample from '../../assets/images/normalFlowExample.png';
import DeltaTHeat from '../../components/heat_graph/deltaTGraph';
import windArrowImage from '../../assets/images/marker/waypoint/marker-icon.png';
import Chart from 'chart.js/auto';
import L from 'leaflet';
import 'leaflet-draw';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import SpeedIcon from '@material-ui/icons/Speed';
import img from '../../routes/point/img';

const styles = theme => ({
    select: {
        color: 'white',
        '&:before': {
            borderColor: 'transparent',
        },
        '&:after': {
            borderColor: 'transparent',
        },
        '& .MuiSelect-icon': {
            color: 'white',
        },
    },
    windDirectionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
    },
    circle: {
        position: 'relative',
        width: '3rem',
        height: '3rem',
        borderRadius: '50%',
        border: '1px solid white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '0.5rem',
    },
    windDirection: {
        position: 'absolute',
        fontSize: '0.6rem',
        color: 'white',
    },
    north: {
        top: '-2px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    south: {
        bottom: '-2px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    east: {
        right: '2px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    west: {
        left: '2px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    windDirectionArrow: {
        position: 'absolute',
        top: '48%',
        left: '52%',
        transform: 'translate(-50%, -50%) rotate(180deg)',
        transition: 'transform 0.3s ease-in-out',
        width: '1rem',
        height: '1.25rem',
    },
});

class DiaryClimate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stationData: '',
            temperature: '',
            air_temperature: '',
            air_humidity: '',
            humidity: '',
            windSpeed: '',
            deltaT: '',
            windDirection: '',
            thermalInversion: '',
            pressure: '',
            datetime: '',
            loading: true,
            stations: [],
            selectedStation: '',
            date: Moment().format('YYYY-MM-DD'),
            intervalId: null,
            flightConditions: '',
            rainData: [],
            windSpeedUnit: 'km/h',
            refreshInterval: 300000
        };
        this.existingIcons = {};
    }

    componentDidMount() {
        this.icon = img;

        const savedInterval = parseInt(localStorage.getItem('refreshInterval'), 10) || this.state.refreshInterval;

        this.setState({ refreshInterval: savedInterval }, () => {
            if (!this.mymap) {
                this.mymap = L.map('mapid', { zoomControl: false, attributionControl: false }).setView(Config.place, 14);
                L.tileLayer(Config.tile_google, Config.tile_google_config).addTo(this.mymap);
                this.featureGroupP = L.featureGroup().addTo(this.mymap);
            }

            this.listStations().then(() => {
                if (this.state.stations.length > 0) {
                    this.updateAllStationsData();
                    this.setState({ selectedStation: this.state.stations[0].id });
                }
            });

            this.startRefreshInterval();
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.refreshInterval !== this.state.refreshInterval) {
            clearInterval(this.state.intervalId);
            this.startRefreshInterval();
        }

        if (prevState.selectedStation !== this.state.selectedStation) {
            this.setState({ loading: true });
            this.getDataById(this.state.selectedStation).finally(() => {
                this.setState({ loading: false });
            });
        }

        if (this.mymap) {
            setTimeout(() => {
                this.mymap.invalidateSize();
            }, 500);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    handleIntervalChange = (event) => {
        const newInterval = parseInt(event.target.value, 10) * 60000;

        localStorage.setItem('refreshInterval', newInterval);

        this.setState({ refreshInterval: newInterval }, () => {
            clearInterval(this.state.intervalId);
            this.startRefreshInterval();
        });
    }

    startRefreshInterval() {
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }

        const intervalId = setInterval(() => {
            this.updateAllStationsData();
        }, this.state.refreshInterval);

        this.setState({ intervalId });
    }

    updateAllStationsData = async () => {
        let mostRecentDatetime = null;

        const stationPromises = this.state.stations.map(async (station) => {
            try {
                const data = await this.getDataById(station.id);
                if (data && data.lat && data.lng) {
                    this.drawMarker(data);
                    if (!mostRecentDatetime || Moment(data.datetime).isAfter(Moment(mostRecentDatetime))) {
                        mostRecentDatetime = data.datetime;
                    }
                }
            } catch (error) {
                console.error(error);
            }
        });

        try {
            await Promise.all(stationPromises);
            if (this.state.selectedStation) {
                this.setState({ loading: true }, async () => {
                    await this.getDataById(this.state.selectedStation);
                    this.setState({ loading: false });
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    drawMarker = (stationIcon) => {
        let dIcon = L.icon({
            iconUrl: stationIcon.img,
            iconAnchor: stationIcon.type === 'O' ? [25, 30] : [13, 33],
            popupAnchor: [0, -25],
        });

        let newLatLng = new L.LatLng(stationIcon.lat, stationIcon.lng);
        let existingIcon = this.existingIcons[stationIcon.id_source];
        if (existingIcon) {
            this.featureGroupP.removeLayer(existingIcon);
        }

        let myLayer = L.marker(newLatLng, { icon: dIcon })
            .on('click', () => {
                this.setState({
                    selectedStation: stationIcon.id_source,
                    loading: true
                }, () => {
                    this.getDataById(stationIcon.id_source);
                });
            });

        myLayer.addTo(this.featureGroupP);
        this.existingIcons[stationIcon.id_source] = myLayer;
    };

    listStations = () => {
        const apiUrl = `${Config.server}/weather_source`;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt'),
            },
        };

        return fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                return res.json();
            })
            .then(
                (result) => {
                    const currentDate = new Date().toISOString().split('T')[0];
                    const updatedStations = result
                        .filter(station => station.datetime.startsWith(currentDate))
                        .sort((a, b) => new Date(b.datetime) - new Date(a.datetime));

                    this.setState({
                        stations: updatedStations,
                    });
                },
                (error) => {
                    console.error(error);
                }
            );
    };

    getDataById = (sourceID) => {
        const apiUrl = `${Config.server}/weather_data/${sourceID}/${this.state.date}`;
        const options = {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('access-token-jwt'),
            },
        };

        return fetch(apiUrl, options)
            .then((res) => {
                if (res.status !== 200) throw res;
                return res.json();
            })
            .then((result) => {
                if (result.length > 0) {
                    const element = result[result.length - 1];
                    const lastDataTime = Moment(element.datetime).format('DD/MM/YY HH:mm');
                    const currentTime = Moment().format('DD/MM/YY HH:mm');
                    const diffInMinutes = Moment(currentTime, 'DD/MM/YY HH:mm').diff(Moment(lastDataTime, 'DD/MM/YY HH:mm'), 'minutes');

                    let iconStation = diffInMinutes >= 0 && diffInMinutes <= 20 ? 22 : 23;

                    const tempMark = {
                        lat: element.lat,
                        lng: element.lng,
                        img: img[iconStation].img,
                        id_source: sourceID,
                        datetime: element.datetime
                    };

                    if (element.addressStation === 255) {
                        const rainData = result.map((entry) => entry.rain);
                        const labels = result.map((entry) => Moment(entry.datetime).format('HH:mm'));
                        this.setState({ rainData }, () => this.generateRainChart(rainData, labels));
                    } else {
                        this.setState({ rainData: [] });
                    }

                    this.setState({
                        temperature: element.air_temperature ? `${element.air_temperature} °C` : '-',
                        air_temperature: element.air_temperature ? `${element.air_temperature}` : '-',
                        humidity: element.air_humidity ? `${element.air_humidity} %` : '-',
                        air_humidity: element.air_humidity ? `${element.air_humidity}` : '-',
                        windSpeed: element.wind_speed ? `${this.state.windSpeedUnit === 'km/h' ? (element.wind_speed * 3.6).toFixed(1) : element.wind_speed.toFixed(1)} ${this.state.windSpeedUnit}` : '-',
                        deltaT: element.delta_t ? parseFloat(element.delta_t).toFixed(1) : '-',
                        windDirection: element.wind_direction ? `${element.wind_direction} °` : null,
                        thermalInversion: !element.temperature
                            ? 'Desabilitado'
                            : Number(element.air_temperature) - (Number(element.temperature) + 5) > 0
                                ? 'Grandes chances de estar ocorrendo inversão térmica'
                                : Number(element.air_temperature) - (Number(element.temperature) + 1) > 0
                                    ? 'Pode estar ocorrendo inversão térmica'
                                    : 'Não há inversão térmica',
                        pressure: element.pressure ? `${element.pressure} hPa` : '-',
                        datetime: element.datetime ? Moment(element.datetime).format("HH:mm  DD/MM/YYYY") : '-',
                        loading: false,
                        flightConditions: element.delta_t >= 2 && element.delta_t <= 8
                            ? 'Condições adequadas'
                            : element.delta_t > 10
                                ? 'Condições inadequadas'
                                : 'Condições arriscadas'
                    });

                    this.drawMarker(tempMark);
                    return tempMark;
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false });
            });
    };

    generateRainChart = (rainData, labels) => {
        const chartElement = document.getElementById('chartRain');
        if (this.rainChart) {
            this.rainChart.destroy();
        }

        const totalRain = rainData.reduce((sum, value) => sum + value, 0).toFixed(1);
        const rainColors = rainData.map(value => value === 0 ? '#FAB288' : '#FA9F34');
        const maxRainValue = Math.max(...rainData);
        const yAxisMax = maxRainValue > 0 ? maxRainValue + 0.1 : 1;
        const ctx = chartElement.getContext('2d');

        this.rainChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Chuva (mm)',
                    data: rainData,
                    backgroundColor: rainColors,
                    borderColor: '#FA9F34',
                    borderWidth: 1,
                    pointBackgroundColor: rainColors,
                    pointBorderColor: rainColors,
                    fill: false,
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        min: 0,
                        max: yAxisMax,
                        ticks: {
                            color: 'white',
                            stepSize: 0.05,
                        },
                        title: {
                            display: true,
                            text: 'Chuva (mm)',
                            color: 'white',
                        },
                        grid: {
                            color: '#6c757d'
                        }
                    },
                    x: {
                        ticks: {
                            color: 'white',
                            maxRotation: 0,
                            autoSkip: true,
                            maxTicksLimit: Math.floor(chartElement.clientWidth / 50),
                        },
                        title: {
                            display: true,
                            text: 'Hora',
                            color: 'white',
                        },
                        grid: {
                            color: '#6c757d',
                        }
                    },
                },
                plugins: {
                    legend: {
                        labels: {
                            color: 'white',
                        },
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                    },
                    title: {
                        display: true,
                        text: `Total de chuva diário: ${totalRain} mm`,
                        color: 'white',
                        position: 'bottom',
                    },
                },
                elements: {
                    line: {
                        tension: 0.1,
                    },
                    point: {
                        radius: 3,
                    },
                },
            },
        });
    };

    handleStationChange = (event) => {
        this.setState({
            selectedStation: event.target.value, loading: true
        });
    };

    toggleWindSpeedUnit = () => {
        this.setState(prevState => {
            const newUnit = prevState.windSpeedUnit === 'km/h' ? 'm/s' : 'km/h';

            localStorage.setItem('windSpeedUnit', newUnit);

            const windSpeedValue = parseFloat(prevState.windSpeed);

            return {
                windSpeedUnit: newUnit,
                windSpeed: this.convertWindSpeed(windSpeedValue, newUnit)
            };
        });
    };

    convertWindSpeed = (value, targetUnit) => {
        if (isNaN(value)) return '-';

        if (targetUnit === 'm/s') {
            return `${(value / 3.6).toFixed(1)} m/s`;
        } else {
            return `${(value * 3.6).toFixed(1)} km/h`;
        }
    };

    render() {
        const { classes, res } = this.props;

        const climateData = [
            { label: 'Temperatura', value: this.state.temperature },
            { label: 'Umidade', value: this.state.humidity },
            { label: 'Vel. do Vento', value: this.state.windSpeed },
            { label: 'Delta-T', value: this.state.deltaT },
            { label: 'Dir. do Vento', value: this.state.windDirection },
            { label: 'Pressão', value: this.state.pressure },
            { label: 'Inversão Térmica', value: this.state.thermalInversion },
        ];

        return (
            <div className='dashboard-card' style={{ minWidth: '10rem', minHeight: '10rem' }}>
                <div className='divContent'
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '.8rem',
                        backgroundColor: '#343a40',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <div className='cardheader' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FilterDramaIcon style={{ width: '1.7rem', height: '1.7rem', color: '#fa7d34' }} />
                            <span className='title' style={{ marginLeft: '0.5rem', color: 'white' }}>
                                {res.CLIMA_}
                            </span>
                        </div>
                        <FormControl style={{ width: 'fit-content' }}>
                            <InputLabel id="refresh-interval-label" style={{ fontSize: '0.875rem', color: 'white' }}>
                                Atualização
                            </InputLabel>
                            <Select
                                labelId="refresh-interval-label"
                                value={this.state.refreshInterval / 60000}
                                onChange={this.handleIntervalChange}
                                className={classes.select}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            zIndex: 2000,
                                        },
                                    },
                                }}
                            >
                                {[1, 3, 5, 10, 15].map((interval) => (
                                    <MenuItem key={interval} value={interval}>
                                        {interval} min
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            onClick={this.toggleWindSpeedUnit}
                            variant="contained"
                            title={this.state.windSpeedUnit === 'km/h' ? 'Direção do vento em km/h' : 'Direção do vento em m/s'}
                            style={{ backgroundColor: 'rgb(250, 125, 52)', color: 'white' }}
                        >
                            {this.state.windSpeedUnit === 'km/h' ? 'KM/H ' : 'M/S '} <SpeedIcon style={{ marginLeft: '0.25rem' }} />
                        </Button>
                        <FormControl style={{ width: 'fit-content' }}>
                            <InputLabel id="station-select-label" style={{ fontSize: '0.875rem', color: 'white' }}>
                                Estação
                            </InputLabel>
                            <Select
                                labelId="station-select-label"
                                value={this.state.selectedStation}
                                onChange={this.handleStationChange}
                                className={classes.select}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            zIndex: 2000,
                                        },
                                    },
                                    PopperProps: {
                                        disablePortal: false,
                                        modifiers: {
                                            preventOverflow: {
                                                enabled: true,
                                                boundariesElement: 'viewport',
                                            },
                                        },
                                    },
                                }}
                            >
                                {this.state.stations.map((station) => (
                                    <MenuItem key={station.id} value={station.id}>
                                        {station.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='cardmiddle'
                        style={{
                            overflowY: 'auto',
                            flexGrow: 1,
                            maxHeight: '100%',
                            padding: '0.5rem',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start'
                        }}
                    >
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '0.5rem', width: '100%' }}>
                            {climateData.map((data, index) => {
                                if (data.label === 'Inversão Térmica') {
                                    const thermalInversionColor = data.value === 'Desabilitado' ? 'white' : data.value === 'Grandes chances de estar ocorrendo inversão térmica'
                                        ? 'red'
                                        : data.value === 'Pode estar ocorrendo inversão térmica'
                                            ? 'yellow'
                                            : 'lawngreen';

                                    return (
                                        <Tooltip key={index} title={
                                            <div
                                                style={{
                                                    backgroundColor: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '4px',
                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                                    width: '25.25rem'
                                                }}
                                            >
                                                {data.value === "Não está ocorrendo inversão térmica"
                                                    ?
                                                    <img style={{ marginTop: '2.5rem', maxHeight: '12rem', borderRadius: '1rem' }} src={normalFlowExample} alt="Fluxo normal" />
                                                    :
                                                    <img style={{ marginTop: '2.5rem', maxHeight: '12rem', borderRadius: '1rem' }} src={thermalInversionExample} alt="Fluxo com inversão térmica" />
                                                }
                                            </div>
                                        }
                                            placement="top"
                                            arrow
                                            classes={{ tooltip: classes.customWidthTooltip }}
                                        >
                                            <div style={{ gridColumn: 'span 3', borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                                <span style={{ display: 'block', color: '#fa7d34' }}>
                                                    {data.label}
                                                </span>
                                                <span style={{ display: 'flex', justifyContent: 'center', color: thermalInversionColor, fontWeight: 'bold', fontSize: '1.25rem' }}>
                                                    {this.state.loading ? <Skeleton width="12rem" /> : data.value}
                                                </span>
                                            </div>
                                        </Tooltip>
                                    );
                                }

                                if (data.label === 'Delta-T') {
                                    let deltaTColor = 'white';
                                    if (this.state.deltaT >= 2 && this.state.deltaT <= 8) {
                                        deltaTColor = 'lawngreen';
                                    } else if (this.state.deltaT > 10) {
                                        deltaTColor = 'red';
                                    } else if (this.state.deltaT === '-') {
                                        deltaTColor = 'white';
                                    } else {
                                        deltaTColor = 'yellow';
                                    }

                                    return (
                                        <Tooltip key={index} title={
                                            <div
                                                style={{
                                                    backgroundColor: '#fff',
                                                    padding: '10px',
                                                    borderRadius: '4px',
                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                                                    width: '22.5rem'
                                                }}
                                            >
                                                <DeltaTHeat
                                                    atual={this.state.deltaT}
                                                    humidade={this.state.air_humidity}
                                                    temperatura={this.state.air_temperature}
                                                />
                                            </div>
                                        }
                                            placement="top"
                                            arrow
                                            classes={{ tooltip: classes.customWidthTooltip }}
                                        >
                                            <div style={{ borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                                <span style={{ display: 'block', color: '#fa7d34' }}>
                                                    {data.label}
                                                </span>
                                                <span style={{ display: 'flex', justifyContent: 'center', color: deltaTColor, fontWeight: 'bold', fontSize: '1.25rem' }}>
                                                    {this.state.loading ? <Skeleton width="4rem" /> : data.value}
                                                </span>
                                            </div>
                                        </Tooltip>
                                    );
                                }

                                if (data.label === 'Dir. do Vento') {
                                    return (
                                        <div key={index} className={classes.windDirectionContainer} style={{ borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                            <span style={{ display: 'block', color: '#fa7d34' }}>{data.label}</span>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {this.state.windDirection ? (
                                                    <span style={{ color: 'white', fontWeight: 'bold', fontSize: '1.25rem', margin: '0.5rem', whiteSpace: 'nowrap' }}>
                                                        {data.value}
                                                    </span>
                                                ) : (
                                                    <Skeleton width="2rem" />
                                                )}
                                                {this.state.windDirection ? (
                                                    <div className={classes.circle}>
                                                        <div className={`${classes.windDirection} ${classes.north}`}>N</div>
                                                        <div className={`${classes.windDirection} ${classes.south}`}>S</div>
                                                        <div className={`${classes.windDirection} ${classes.east}`}>L</div>
                                                        <div className={`${classes.windDirection} ${classes.west}`}>O</div>
                                                        <img
                                                            src={windArrowImage}
                                                            alt="Direção do vento"
                                                            className={classes.windDirectionArrow}
                                                            style={{ transform: `translate(-50%, -50%) rotate(${parseFloat(this.state.windDirection) + 180}deg)` }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Skeleton variant="rect" width="1rem" height="1rem" className={classes.windDirectionArrow} />
                                                )}
                                            </div>
                                        </div>
                                    );
                                }

                                return (
                                    <div key={index} style={{ borderRadius: '0.5rem', backgroundColor: '#252a2f', textAlign: 'center' }}>
                                        <span style={{ display: 'block', color: '#fa7d34' }}>
                                            {data.label}
                                        </span>
                                        <span style={{ display: 'flex', justifyContent: 'center', color: 'white', fontWeight: 'bold', fontSize: '1.25rem' }}>
                                            {this.state.loading ? <Skeleton width="4rem" /> : data.value}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                        {this.state.rainData.length > 0 && (
                            <div style={{ marginTop: '1rem' }}>
                                <canvas id="chartRain" height="150"></canvas>
                            </div>
                        )}
                        <div className="divContent divRelative map-p" style={{ backgroundColor: 'rgb(52, 58, 64)', height: '16rem', minHeight: '8rem', width: '100%', padding: '0', borderRadius: '0.5rem' }}>
                            <div className="containerTop" style={{ padding: '0' }}></div>
                            <div className={'wrapperMap'}>
                                <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: '1111', display: 'none' }}></div>

                                <div id="mapid" className={classes.mapCommonStyle}></div>
                                <div className="zoom">
                                    <Button className="buttonS buttonMenu" onClick={() => this.zoomInL()}>
                                        <AddIcon className="iconMap" />
                                    </Button>
                                    <Button className="buttonS buttonMenu" onClick={() => this.zoomOutL()}>
                                        <RemoveIcon className="iconMap" />
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cardfooter'>
                        <span className='cardfooter-content' style={{ paddingTop: '.7rem' }}>
                            <span style={{ display: 'inline-table', paddingLeft: '1rem', width: '50%' }}>
                                <span style={{ display: 'table-row', width: '100%', color: '#fa7d34' }}>
                                    {res.ATUALIZADO}
                                </span>
                            </span>
                            <span style={{ display: 'inline-table', paddingRight: '1rem', width: '50%', fontSize: '1.25rem', fontWeight: 'bold', color: 'white' }}>
                                <span style={{ display: 'table-row', width: '100%' }}>
                                    {this.state.loading ? <Skeleton width="10rem" /> : this.state.datetime}
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(DiaryClimate);
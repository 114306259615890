import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@material-ui/core/IconButton';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Button from '@material-ui/core/Button';

import Config from '../../constants/appConfig';
import MaterialIcon from '../../components/MaterialIcon';

import { notification } from 'antd';

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rowCount, res } = this.props;

    const columnData = [
      { id: 'name', disablePadding: true, label: 'Prefixo' },
      { id: 'serial', disablePadding: true, label: 'Série' },
      { id: 'aircraftbrand', disablePadding: true, label: 'Fabricante' },
      { id: 'aircraftmodel', disablePadding: true, label: 'Modelo' },
      { id: 'actions', disablePadding: false, label: 'Ações' }
    ];

    return (
      <TableHead>
        <TableRow>
          {columnData.map(column => (
            <TableCell
              key={column.id}
              padding={column.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === column.id ? order : false}
              style={{ fontWeight: 'bold', padding: '12px 16px' }}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flex: '0 0 auto',
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
});

let EnhancedTableToolbar = props => {
  const { classes } = props;

  return (
    <Toolbar className={classNames(classes.root)}>
      <div className={classes.title}>
        <h1 className="pageTitle">Diário de Bordo</h1>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 600,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'name',
      data: [],
      page: 0,
      rowsPerPage: 5,
      filtro: '',
    };
  }

  componentDidMount() {
    this.updateList();
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  clear = () => {
    this.setState({
      filtro: ''
    });
  };

  updateList = () => {
    const apiUrl = Config.server + '/plane';
    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            data: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          } else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, filtro, clear, res, link } = this.props;
    const { data, order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const editIcon = id => (
      <IconButton onClick={event => (link('/app/diario-de-bordo/edit/' + id))}>
        <ListAltIcon color="primary" />
      </IconButton>
    );

    const limpaCampo = () => {
      document.getElementById('campoFiltro').value = '';
      clear();
    };

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar filtro={this.state.filtro} handleChange={this.handleChange} clear={this.clear} res={res} />
        <div className={classes.tableWrapper}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Typography variant="h6" style={{ marginLeft: '20px', marginBottom: '10px' }}>
              Aeronaves
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '0.5rem' }}>
              <SearchIcon style={{ marginRight: '.5rem' }} />
              <TextField
                placeholder={res.FILTRO}
                margin="normal"
                onChange={this.handleChange('filtro')}
                id="campoFiltro"
                style={{ width: '200px' }}
              />
              {filtro && (
                <IconButton style={{ marginLeft: '.5rem' }} onClick={() => limpaCampo()}>
                  <MaterialIcon icon="clear" />
                </IconButton>
              )}
            </div>
          </div>
          <Table className={classes.table} aria-labelledby="tableTitle" style={{ tableLayout: 'auto' }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              res={res}
            />
            <TableBody>
              {data
                .filter(item => {
                  return (
                    item.name.includes(this.state.filtro) ||
                    (item.serial && item.serial.includes(this.state.filtro)) ||
                    (item.aircraftbrand && item.aircraftbrand.includes(this.state.filtro)) ||
                    (item.aircraftmodel && item.aircraftmodel.includes(this.state.filtro))
                  );
                })
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => (
                  <TableRow hover tabIndex={-1} key={n.id}>
                    <TableCell>{n.name}</TableCell>
                    <TableCell>{n.serial}</TableCell>
                    <TableCell>{n.aircraftbrand}</TableCell>
                    <TableCell>{n.aircraftmodel}</TableCell>
                    <TableCell>
                      {editIcon(n.id)}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10]}
          labelRowsPerPage="Linhas por página"
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const EnhancedTable1 = withStyles(styles)(EnhancedTable);

const Section = (props) => {
  const { link, res } = props;
  return (
    <div className="rowForm">
      <EnhancedTable1 link={link} res={res} />
    </div>
  );
};

export default Section;